<template>
    <v-container>
        <page-filters-section top-img="/img/claim/coins-header.png">
            <h1 class="text-center">Claim SCRL token</h1>
            <span class="text-center">Here you can claim tokens for Team</span>
            <br/><br/>
        </page-filters-section>

        <v-col class="page-content">
            <v-row>
                <v-col cols="5" class="text-center hidden-sm-and-down">
                    <img class="hero-image ma-auto" src="/img/claim/coin-yellow-wizard.png"
                         alt="Wizard claiming SCRL"/>
                </v-col>
                <v-col cols="12" md="7" v-if="!isWalletConnected">
                    <connect-wallet></connect-wallet>
                </v-col>
                <v-col cols="12" md="7" v-if="!vestingsLoaded && isWalletConnected">
                    <v-progress-circular
                        size="300"
                        indeterminate
                        style="width: 100% !important;"
                    ></v-progress-circular>
                </v-col>
                <v-col cols="12" md="7" v-if="vestingsLoaded && isWalletConnected">
                    <dialog-box
                        title="Claim details"
                        v-bind:model="detailsDialog"
                        v-on:dialogClosed="detailsDialog=false"
                    >
                        <!-- All vestings -->
                        <div class="vestings">
                            <v-card class="vesting m-2" v-for="item in vestings" :key="item.id">
                                <div class="vesting__row">
                                    <div class="vesting__name">End date</div>
                                    <div class="vesting__value caption">
                                        {{ item.dateEnd | moment("Do MMMM YYYY") }}
                                    </div>
                                </div>

                                <div class="vesting__row">
                                    <div class="vesting__name">Total Tokens</div>
                                    <div class="vesting__value">
                                        {{ item.totalTokens | removeDecimals | localFormatNumber }}
                                    </div>
                                </div>

                                <div class="vesting__row">
                                    <div class="vesting__name">Claimed Tokens</div>
                                    <div class="vesting__value">
                                        {{ item.claimedTokens | removeDecimals | localFormatNumber }}
                                    </div>
                                </div>

                                <div class="vesting__row">
                                    <div class="vesting__name">Left Tokens</div>
                                    <div class="vesting__value">
                                        {{ item.leftTokens | removeDecimals | localFormatNumber }}
                                    </div>
                                </div>

                                <div class="vesting__row">
                                    <div class="vesting__name">Tokens to claim</div>
                                    <div class="vesting__value">
                                        {{ item.tokensToClaim | removeDecimals | localFormatNumber }}
                                    </div>
                                </div>
                            </v-card>
                        </div>
                    </dialog-box>

                    <!-- Sum of vestings -->
                    <div class="mt-4 vesting-box">
                        <div class="vesting">
                            <div class="vesting__row">
                                <div class="vesting__name">Total Tokens</div>
                                <div class="vesting__value">
                                    {{ vestingsSum.totalTokens | removeDecimals | localFormatNumber }}
                                </div>
                            </div>

                            <div class="vesting__row">
                                <div class="vesting__name">Claimed Tokens</div>
                                <div class="vesting__value">
                                    {{ vestingsSum.claimedTokens | removeDecimals | localFormatNumber }}
                                </div>
                            </div>

                            <div class="vesting__row">
                                <div class="vesting__name">Left Tokens</div>
                                <div class="vesting__value">
                                    {{ vestingsSum.leftTokens | removeDecimals | localFormatNumber }}
                                </div>
                            </div>

                            <div class="vesting__row">
                                <div class="vesting__name">Tokens to claim</div>
                                <div class="vesting__value">
                                    {{ vestingsSum.tokensToClaim | removeDecimals | localFormatNumber }}
                                </div>
                            </div>
                        </div>

                        <div class="mt-8">
                            <v-btn
                                large
                                class=""
                                @click="claimTokens()"
                                v-bind:disabled="!vestingsLoaded || vestingsSum.tokensToClaim<=0"
                            >
                                Claim
                            </v-btn>
                            <v-btn
                                large
                                class="pull-right"
                                @click="detailsDialog=true"
                                v-bind:disabled="!vestingsLoaded">
                                <v-icon>mdi-alert-circle-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-col>

            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import DialogBox from "../components/dialog-box";
import PageFiltersSection from "../components/page-filters-section";
import VestingTeamContract from "@/services/contracts/vestingTeamContract";
import ConnectWallet from "@/views/ConnectWallet";

export default {
    components: {ConnectWallet, PageFiltersSection, DialogBox},

    data() {
        return {
            vestingContract: null,
            detailsDialog: false,
            isConnected: false,
            vestingsLoaded: false,
            vestings: null,
            vestingsSum: null,
        };
    },

    mounted: function () {
        if (this.isWalletConnected) {
            this.fetchMyVestings();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.fetchMyVestings();
            });
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        wizardsAmountToClaim() {
            return this.myStakesAmounts.unstaked - this.userClaimedAmount;
        },
        bscUrl() {
            return process.env.VUE_APP_BLOCKCHAIN_BLOCK_EXPLORER_URL + 'address/';
        }
    },
    methods: {
        fetchMyVestings() {
            this.vestingsLoaded = false;
            this.vestingsSum = null;
            this.vestings = null;

            this.vestingContract = new VestingTeamContract(this.web3);
            this.vestingContract.getVestings().then((value) => {
                this.vestingsSum = value[value.length - 1];
                this.vestings = value.slice(0, -1);
                this.vestingsLoaded = true;
            });
        },
        claimTokens() {
            this.vestingContract.claimTokens();
        },
    }
};

</script>

<style lang="scss" scoped>
img.hero-image {
    width: 80%;
    max-width: 475px;
    height: auto;
}

.vesting-box {
    margin-left: 30px;
    width: 300px;
}

.v-application {
    a {
        color: #fff;
    }
}

.contract-address {
    img {
        height: 18px;
    }
}

.vesting {
    &__row {
        margin-top: 10px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__name {
        padding: 5px 20px;
        background: var(--v-buttonBg-base);
    }

    &__value {
        padding: 5px 30px;
    }
}

.vestings {
    display: grid;
    grid-template-columns: repeat(2, 1fr );
    gap: 30px;
    color: #ccc;

    .vesting {
        &__name {
            padding: 3px 10px;
        }

        &__value {
            padding: 3px 15px;
        }
    }
}

@media only screen and (max-width: $sm) {
    .vesting-box {
        width: 70%;
        margin-left: 15%;

    }
}

</style>
