import VestingContract from "./vestingContract";

export const vestingContractAddress = process.env.VUE_APP_CONTRACT_VESTING_TEAM;

export default class VestingTeamContract extends VestingContract {
    constructor(web3) {
        super(web3);
        this.contractAddress = vestingContractAddress;
    }
}
